<template>
    <table class="table table-sm">
        <thead>
            <tr>
                <th>№</th>
                <th>{{ $t('product_info') }}</th>
                <th>{{ $t('quantity') }}</th>
                <th>{{ $t('total') }}</th>
                <th>{{ $t('total_dropshipper') }}</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(item, index) in items" :key="index">
                <td class="text-center">{{ index + 1 }}</td>
                <td>
                    <p><strong>{{ $t('product') }}:</strong> {{ item.product }}</p>
                    <div v-if="item.stages.length > 0">
                        <strong>{{ $t('characteristic') }}: </strong><i>{{ item.characteristic }}</i>
                        <table class="table table-borderles">
                            <thead>
                                <th>{{ $t('stage') }}</th>
                                <th>{{ $t('status') }}</th>
                                <th>{{ $t('photo') }}</th>
                                <th>{{ $t('photo_layout') }}</th>
                                <th>{{ $t('status_ready') }}</th>
                            </thead>
                            <tr v-for="(stage, idx) in item.stages" :key="idx">
                                <td>{{ stage.name }}</td>
                                <td><b-badge v-if="stage.status === 0" variant="primary">{{ $t('new') }}</b-badge></td>
                                <td><b-img-lazy :src="apiUrl + stage.photo" fluid width="150" thumbnail/></td>
                                <td><b-img-lazy :src="apiUrl + stage.photo_layout" fluid width="150" thumbnail/></td>
                                <td>{{ stage.ready }}</td>
                            </tr>
                        </table>
                    </div>
                </td>
                <td>
                    <!--<b-input-group size="sm">
                        <b-form-input type="number" v-model.number="item.quantity" disabled/>
                        <b-input-group-append>
                            <b-button variant="outline-dark" @click="changeQty(item.id, 'minus')">
                                <fa icon="minus" />
                            </b-button>
                            <b-button variant="outline-dark" @click="changeQty(item.id, 'plus')">
                                <fa icon="plus" />
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                    <p>-->
                        {{ item.quantity }}
                        <!--<fa icon="info-circle" :id="`tooltip-target-${item.id}`" class="text-primary"/>
                        <b-tooltip :target="`tooltip-target-${item.id}`" triggers="hover">
                            {{ $t('text_qty_info', {'0': item.quantity, '1': item.quantity_in_production}) }}
                        </b-tooltip>
                    </p>-->
                </td>
                <td>
                    <span class="text-danger"><strong>{{ item.total_price | currency }}</strong></span>
                </td>
                <td>
                     <span class="text-danger">{{ item.total_price_drop | currency }}</span>
                </td>
                <td>
                    <!--<b-button-group v-if="item.status === 'Новий'">
                        <router-link :to="{ name: 'order.products.edit', params: { 'id': orderId } }" class="btn btn-sm btn-warning"><fa icon="pencil-alt" /></router-link>
                        <b-button size="sm" variant="danger">
                            <fa icon="trash" />
                        </b-button>
                    </b-button-group>-->
                </td>
            </tr>
        </tbody>
    </table>
</template>
<script>
export default {
    props: {
        items: {
            type: Array,
            default: null
        },
        orderId: {
            type: String,
            default: null
        }
    },
    data: () => ({
        apiUrl: process.env.VUE_APP_ENDPOINT_URL
    }),
    methods: {
        changeQty (id, type) {
            console.log(id)
            console.log(type)
        }
    }
}
</script>
